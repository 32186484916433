import React, { FC, useEffect, useState } from "react";
import "./Dashboard.scss";
import { useNavigate } from "react-router-dom";
import "../../assets/images/logo-prague-white.svg";
import "../../assets/images/ContentLogo.png";
import "../../App.tsx";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";

const contentLogo = require("../../assets/images/INTERN.png");

interface DashboardProps {}

interface Partner {
  stagesPosition: string;
  descr: string;
  fieldOfWork: string;
  id: string;
  startDate: string;
  stagesLocation: string;
}

interface Partners {
  [partnerLevel: string]: {
    [partner: string]: Partner[];
  };
}

const Dashboard: FC<DashboardProps> = () => {
  const MaxCards = 4;
  const [cardsToShow, setCardsToShow] = useState(MaxCards);
  const [partnersData, setPartnersData] = useState<Partners | null>(null);
  const [archivedData, setArchivedData] = useState<Partners | null>(null);
  const [unableFetchStagesElement, setUnableFetchStagesElement] =
    useState<React.ReactNode | null>(null);
  const navigate = useNavigate();
  const [partnersCardsToShow, setPartnersCardsToShow] = useState<{ [key: string]: number }>({});
  const [archivedCardsToShow, setArchivedCardsToShow] = useState<{ [key: string]: number }>({});


  // const handleShowMore = () => {
  //   setCardsToShow(cardsToShow + MaxCards);
  // };
  // const handleShowLess = () => {
  //   setCardsToShow(cardsToShow - MaxCards);
  // };
  const handleShowMore = (partner: string) => {
    setPartnersCardsToShow((prevState) => ({
      ...prevState,
      [partner]: (prevState[partner] || 0) + MaxCards,
    }));
  };

  const handleShowLess = (partner: string) => {
    setPartnersCardsToShow((prevState) => ({
      ...prevState,
      [partner]: ((prevState[partner] || 0) - MaxCards),
    }));
  };

  const handleArchivedShowMore = (partner: string) => {
    setArchivedCardsToShow((prevState) => ({
      ...prevState,
      [partner]: (prevState[partner] || 0) + MaxCards,
    }));
  };

  const handleArchivedShowLess = (partner: string) => {
    setArchivedCardsToShow((prevState) => ({
      ...prevState,
      [partner]: ((prevState[partner] || 0) - MaxCards),
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/user/getStages");
        const data = await response.json();
        setPartnersData(data);
      } catch (error) {
        console.error("Error fetching partners data:", error);
        setUnableFetchStagesElement(
          <Container className="unableFetchStagesElement"> //TODO
            Nepodařilo se načíst stáže.
          </Container>
        );
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/user/getArchive");
        const data = await response.json();
        setArchivedData(data);
      } catch (error) {
        console.error("Error fetching partners data:", error);
        setUnableFetchStagesElement(
            <Container className="unableFetchStagesElement"> //TODO
              Nepodařilo se načíst stáže.
            </Container>
        );
      }
    };

    fetchData();
  }, []);


  const handleViewDetails = (id: string) => {
    navigate(`/detailedviewoninternship/${id}`);
  };

  // Function to sort partners by partnerLevel in the specified order
  const sortPartnersByPartnerLevel = (
      partnersData: Partners | null,
      order: string[]
  ): Partners => {
    const sortedPartnersData: Partners = {};

    if (partnersData) {
      order.forEach((partnerLevel) => {
        if (partnersData.hasOwnProperty(partnerLevel)) {
          sortedPartnersData[partnerLevel] = partnersData[partnerLevel];
        }
      });
    }

    return sortedPartnersData;
  };

  // Define the order of partner levels for sorting
  const order = [
    "FOUNDING PARTNER",
    "CO-FOUNDING PARTNERS",
    "PARTNERS & SUPPORTERS",
    "CURRICULUM PARTNERS",
    "COMMUNITY PARTNERS AND SUPPORTERS"
  ];

  // Sort the partnersData by partnerLevel in the specified order
  const sortedPartnersData = sortPartnersByPartnerLevel(partnersData, order);
  const sortedArchivedData = sortPartnersByPartnerLevel(archivedData, order);


  return (
    <div className="pageContent">
      <HeaderComponent />
      <Container className="newContent">
        <img src={contentLogo} alt="content logo" className="content-logo" />
        <Container style={{ maxWidth: 1140 }} className="nadpis">
          <h4 className="text-uppercase strong">Dashboard</h4>
          <h2 className="">42 Prague internship platform</h2>
          <p className="">Find your perfect internship experience!</p>
        </Container>
      </Container>
      {unableFetchStagesElement}
      <Container className="card-container" style={{ maxWidth: 1140 }}>
        <div>
          {unableFetchStagesElement}
          {partnersData &&
              Object.entries(sortedPartnersData).map(([partnerLevel, partners]) => (
              <div className="active-stages" key={partnerLevel}>
                <h2>{partnerLevel}</h2>
                {Object.entries(partners).map(([partner, partnerData]) => (
                  <React.Fragment key={partner}>
                    <h3>{partner}</h3>
                    <Row xs={1} sm={2} md={3} lg={4}>
                      {partnerData.slice(0, partnersCardsToShow[partner] || MaxCards).map((data) => (
                        <Col key={data.id} className="mb-4">
                          <Card>
                            <Card.Body>
                              <Card.Title className="strong">
                                {data.stagesPosition}
                              </Card.Title>
                              <Card.Text className="card-text">
                                <span className="strong">Field of Work:</span>
                                <br />
                                {data.fieldOfWork}
                                <br />
                                <span className="strong">Location: </span>
                                <br />
                                {data.stagesLocation}
                                <br />
                                <span className="strong">Start Date: </span>
                                <br />
                                {data.startDate}
                                <Button
                                  variant="primary"
                                  className="button"
                                  onClick={() => handleViewDetails(data.id)}
                                >
                                  View Details
                                </Button>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                    {partnerData.length > (partnersCardsToShow[partner] || MaxCards) && (
                        <Button
                            variant="primary"
                            className="show-more-button"
                            onClick={() => { handleShowMore(partner); handleShowMore(partner);   } }
                        >
                          Show More
                        </Button>
                    )}
                    {partnerData.length > MaxCards && partnersCardsToShow[partner] && partnersCardsToShow[partner] > MaxCards && (
                        <Button
                            variant="primary"
                            className="show-more-button"
                            onClick={() => handleShowLess(partner)}
                        >
                          Show Less
                        </Button>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
        </div>
        <div className="archived-stages">
          {unableFetchStagesElement}
          {archivedData &&
            Object.entries(archivedData).map(([partnerLevel, partners]) => (
              <>
                <h2>Archived Internships</h2>
                <div key={partnerLevel}>
                  {Object.entries(partners).map(([partner, partnerData]) => (
                    <React.Fragment key={partner}>
                      <h3>{partner}</h3>
                      <Row xs={1} sm={2} md={3} lg={4}>
                        {partnerData.slice(0, archivedCardsToShow[partner] || MaxCards).map((data) => (
                          <Col key={data.id} className="mb-4">
                            <Card>
                              <Card.Body>
                                <Card.Title className="strong">
                                  {data.stagesPosition}
                                </Card.Title>
                                <Card.Text className="card-text">
                                  <span className="strong">Field of Work:</span>
                                  <br />
                                  {data.fieldOfWork}
                                  <br />
                                  <span className="strong">Location: </span>
                                  <br />
                                  {data.stagesLocation}
                                  <br />
                                  <span className="strong">Start Date: </span>
                                  <br />
                                  {data.startDate}
                                  <Button
                                    variant="primary"
                                    className="archived-button"
                                    onClick={() => handleViewDetails(data.id)}
                                  >
                                    View Details
                                  </Button>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                      {partnerData.length > (archivedCardsToShow[partner] || MaxCards) && (
                          <Button
                              variant="primary"
                              className="archived-show-more-button"
                              onClick={() => { handleArchivedShowMore(partner); handleArchivedShowMore(partner);   } }
                          >
                            Show More
                          </Button>
                      )}
                      {partnerData.length > MaxCards && archivedCardsToShow[partner] && archivedCardsToShow[partner] > MaxCards && (
                          <Button
                              variant="primary"
                              className="archived-show-more-button"
                              onClick={() => handleArchivedShowLess(partner)}
                          >
                            Show Less
                          </Button>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </>
            ))}
        </div>
      </Container>
      <FooterComponent />
    </div>
  );
};

export default Dashboard;
