import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Futura PT/Futura Light font.ttf";
import "./fonts/Futura PT/Futura Bold font.ttf";
import "./fonts/Futura PT/Futura Heavy font.ttf";
import "./fonts/Futura PT/Futura Book font.ttf";
import "./fonts/Futura PT/Futura Book Italic font.ttf";
import "./fonts/Futura PT/Futura Heavy Italic font.ttf";
import "./fonts/TT Hoves/TT Hoves Pro Trial Medium.ttf";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
