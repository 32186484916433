import React, { FC } from "react";
import "../../App.tsx";
import "../../App.scss";
import "./FooterComponent.scss";
import { Container } from "react-bootstrap";

const instagram =
  require("../../assets/images/instagram_adobe_express.svg").default;
const linkedin =
  require("../../assets/images/linkedin_adobe_express.svg").default;
const logo = require("../../assets/images/logo-prague-white.svg").default;
const skoda =
  require("../../assets/images/new_Skoda_Wordmark_RGB_White.svg").default;

interface FooterComponentProps {}
const FooterComponent: FC<FooterComponentProps> = () => {
  return (
    <footer className="footer bg-black footerComponent">
      <div className="footer__top">
        <Container className="container">
          <div className="d-md-flex justify-content-between py-4">
            <div className="d-md-flex align-items-center">
              <h4 className="h4">STAY UP TO DATE WITH 42 PRAGUE</h4>
              <a
                href="https://www.42prague.com/news"
                className="btn btn--teal ml-md-4"
              >
                Take me there
              </a>
            </div>
            <div className="d-flex align-items-center footer__socials mt-4 mt-md-0">
              <a href="https://www.instagram.com/42prague/" target="_blank">
                <img
                  src={instagram}
                  alt="logo instagram"
                  className="ico ico--medium mx-2"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/42-prague/about/"
                target="_blank"
              >
                <img
                  src={linkedin}
                  alt="logo linkedin"
                  className="ico ico--medium mx-2"
                />
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className="py-4">
        <div className="container">
          <div className="d-sm-flex justify-content-between align-items-center">
            <div>
              <p className="fs-16 footer-email">
                <span>42 Prague</span>
                <br />
                <a href="mailto:info@42prague.com" className="email">
                  info@42prague.com
                </a>
              </p>
            </div>

            <div className="footer__logos">
              <a href="/">
                <img src={logo} width="120" height="50" />
              </a>
              <a href="https://www.skoda-auto.com/" target="_blank">
                <img src={skoda} width="180" height="50" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
