import "./App.scss";
import Dashboard from "./components/Dashboard/Dashboard";
import LoginComponent from "./components/LoginComponent/LoginComponent";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ManualControlUsers from "./components/ManualControlUsers/ManualControlUsers";
import CreateNewInternship from "./components/CreateNewInternship/CreateNewInternship";
import PartnersAdministration from "./components/PartnersAdministration/PartnersAdministration";
import CreateNewStudentLogin from "./components/CreateNewStudentLogin/CreateNewStudentLogin";
import InfoPage from "./components/InfoPage/InfoPage";
import { Spinner } from "react-bootstrap";
import "./assets/images/logo-prague-white.svg";
import "./assets/images/new_Skoda_Wordmark_RGB_White.svg";
import "./assets/images/linkedin_adobe_express.svg";
import "./assets/images/instagram_adobe_express.svg";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { StagesControllerApiAxiosParamCreator } from "./apis/stages-controller-api";
import CreateNewPartner from "./components/CreateNewPartner/CreateNewPartner";
import DetailedViewOnInternship from "./components/DetailedViewOnInternship/DetailedViewOnInternship";
import { useParams } from "react-router-dom";
import SettingsComponent from "./components/SettingsComponent/SettingsComponent";

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { getStages } = StagesControllerApiAxiosParamCreator({});
  const { id } = useParams();
  const [scrolled, setScrolled] = useState(false);

  const role = localStorage.getItem("role");

  useEffect(() => {
    getStages()
      .then((data) => {
        console.log(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrolled(scrollPosition > 600); // Change the scroll range as needed
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App">
      {loading && <Spinner animation="border" size="sm" role="status" />}
      {!loading && (
        <>
          <section className="App-header">
            <BrowserRouter>
              <Routes>
                <Route
                  path="/dashboard"
                  element={
                    role === "admin" || role === "user" ? (
                      <Dashboard />
                    ) : (
                      <Navigate replace to="/" />
                    )
                  }
                />
                <Route
                  path="/manualcontroluser"
                  element={
                    role === "admin" ? (
                      <ManualControlUsers id={0} username={""} password={""} />
                    ) : (
                      <Navigate replace to="/dashboard" />
                    )
                  }
                ></Route>
                <Route
                  path="/createnewinternship"
                  element={
                    role === "admin" ? (
                      <CreateNewInternship />
                    ) : (
                      <Navigate replace to="/dashboard" />
                    )
                  }
                ></Route>
                <Route path="/" element={<LoginComponent />}></Route>
                <Route
                  path="/createnewstudent"
                  element={
                    role === "admin" ? (
                      <CreateNewStudentLogin />
                    ) : (
                      <Navigate replace to="/dashboard" />
                    )
                  }
                ></Route>
                <Route
                  path="/createnewpartner"
                  element={
                    role === "admin" ? (
                      <CreateNewPartner />
                    ) : (
                      <Navigate replace to="/dashboard" />
                    )
                  }
                ></Route>
                <Route
                  path="/infopage"
                  element={
                    role === "user" || role === "admin" ? (
                      <InfoPage />
                    ) : (
                      <Navigate replace to="/dashboard" />
                    )
                  }
                ></Route>
                <Route
                  path="/detailedviewoninternship/:id"
                  element={
                    role === "user" || role === "admin" ? (
                      <DetailedViewOnInternship id={parseInt(id || "")} />
                    ) : (
                      <Navigate replace to="/dashboard" />
                    )
                  }
                ></Route>
                <Route
                  path="/partnersadministration"
                  element={
                    role === "admin" ? (
                      <PartnersAdministration />
                    ) : (
                      <Navigate replace to="/dashboard" />
                    )
                  }
                ></Route>
                <Route
                  path="/settings"
                  element={
                    role === "user" || role === "admin" ? (
                      <SettingsComponent />
                    ) : (
                      <Navigate replace to="/dashboard" />
                    )
                  }
                ></Route>
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </BrowserRouter>
          </section>
        </>
      )}
    </div>
  );
}

export default App;
