import React, {ChangeEvent, FC, FormEvent, useState} from 'react';
import './CreateNewStudentLogin.scss';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  FormGroup,
  FormLabel, FormSelect,
  InputGroup,
  Row
} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";

interface CreateNewStudentLoginProps {}

const CreateNewStudentLogin: FC<CreateNewStudentLoginProps> = () => {


    const [validated, setValidated] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity()) {
      try {
        const elements = form.elements as HTMLFormControlsCollection;
        const username = (elements.namedItem(
            'validationCustomUsername'
        ) as HTMLInputElement).value;
        const password = (elements.namedItem(
            'validationCustomPassword'
        ) as HTMLInputElement).value;
        const role = (elements.namedItem(
            'validationCustomRole'
        ) as HTMLSelectElement).value;

        const response = await fetch('/api/admin/addUsers', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
            role,
          }),
        });

        // Parse the response body as JSON data
        const responseData = await response.json();

        if (response.ok && responseData === true) {
          // User created successfully, handle the response or perform any necessary actions
          console.log('User created!');
          alert("User created.")
          window.location.href = "/manualcontroluser"
        } else {
          // Error occurred, handle the error or display an error message
          console.error('Error creating user');
          alert("Error creating user")
        }
      } catch (error) {
        // Network error occurred, handle the error or display an error message
        console.error('Network error', error);
      }
    }

    setValidated(true);
  };




  return (
      <div className="pageContent">
        <HeaderComponent />
    <Container>
      <div className="newUser">
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Username"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="Password"
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomRole">
            <Form.Label>Role</Form.Label>
            <InputGroup hasValidation>
              <FormSelect
                aria-describedby="inputGroupPrepend"
                required
              >
                <option>
                  admin
                </option>
                <option>
                  user
                </option>
              </FormSelect>
            </InputGroup>
          </Form.Group>
          <Button type="submit">Save</Button>
        </Form>
      </div>
    </Container>
        <FooterComponent />
        </div>
  );
};
export default CreateNewStudentLogin;
