import React, { FC, useEffect, useState } from "react";
import "./ManualControlUsers.scss";
import { useNavigate } from "react-router-dom";
import { Button, Container, Table, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";

interface ManualControlUsersProps {
  id: number;
  username: string;
  password: string;
}

const ManualControlUsers: FC<ManualControlUsersProps> = ({ id }) => {
  const navigate = useNavigate();
  const [newPass, setNewPass] = useState<Record<number, string>>({});
  const [users, setUsers] = useState<ManualControlUsersProps[]>([]);
  const handleCreateUserClick = () => {
    navigate("/createnewstudent");
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/api/admin/getUsers");
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleDeleteUser = async (username: string) => {
    try {
      console.log(username);
      await axios.delete(`/api/admin/deleteUsers`, {
        params: { username: username },
      });
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.username !== username)
      );
      alert("User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user.");
    }
  };

  const handleNewPasswordButton = async (id: number, username: string) => {
    try {
      const newPasswordValue = newPass[id];
      const response = await axios.put(`/api/admin/changePassAdm`, {
        username: username,
        password: newPasswordValue,
      });

      if (response.status === 200) {
        alert("Password changed successfully!");
      } else {
        alert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while changing the password.");
    }
  };

  const handlePasswordInputChange = (id: number, value: string) => {
    setNewPass((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <div className="pageContent">
      <HeaderComponent />
      <Container className="userTab">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Username</th>
              <th>Password</th>
              <th>Control</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(users) &&
              users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.username}</td>
                  <td>
                    <Form.Control
                      type="password"
                      value={newPass[user.id] || ""}
                      onChange={(e) =>
                        handlePasswordInputChange(user.id, e.target.value)
                      }
                      placeholder="Enter new password"
                    />
                  </td>
                  <td>
                    <Button style={{display: "inline-flex"}}
                        onClick={() =>
                            handleNewPasswordButton(user.id, user.username)
                        }
                    >
                      Change Password
                    </Button>
                    <Button onClick={() => handleDeleteUser(user.username)}>
                      Delete user
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Button onClick={handleCreateUserClick}>Create new user</Button>
      </Container>
      <FooterComponent />
    </div>
  );
};

export default ManualControlUsers;
