import React, { FC, useState } from "react";
import "../../App.tsx";
import "../../App.scss";
import "../../assets/images/logo-prague-white.svg";
import { Col, Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import axios from "axios";
import "./HeaderComponent.scss";
import "../../assets/images/settings.svg";

interface HeaderComponentProps {}

const logo = require("../../assets/images/logo-prague-white.svg").default;
const role = localStorage.getItem("role");

const HeaderComponent: FC<HeaderComponentProps> = () => {
  const [showAdminMenu, setShowAdminMenu] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  const handleLogout = () => {
    axios
      .get("/api/user/logout")
      .then((response) => {
        localStorage.removeItem("role");
        console.log("'role' removed from local storage.");
        window.location.href="/";
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };
  const handleAdminMenuEnter = () => {
    setShowAdminMenu(true);
  };

  const handleAdminMenuLeave = () => {
    setShowAdminMenu(false);
  };
  return (
    <header className={`header ${scrolled ? "scrolled" : ""}`}>
      <Container className="header-container">
        <Row>
          <Col>
            <a href="/dashboard" className="logo header_logo">
              <img
                src={logo}
                alt="logo 42Univerzity"
                className="logo"
                width={122}
                height={61.67}
              />
            </a>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col>
            <div className="header_right">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <nav className="menu">
                  <ul className="menu__list mb-0">
                    <li className="menu__item">
                      <a href="/infopage" className="menu-item">
                        INFO
                      </a>
                    </li>
                    <li className="menu__item">
                      <a href="/dashboard" className="menu-item">
                        DASHBOARD
                      </a>
                    </li>
                    {role === "admin" && (
                      <li className="menu__item has-submenu menu-item">
                        ADMINISTRATION
                        <div className="subMenu">
                          <ul className="container">
                            <li className="subMenu__item">
                              <a
                                href="/manualcontroluser"
                                className="menu-item"
                              >
                                USERS ADMINISTRATION
                              </a>
                            </li>
                            <li className="subMenu__item">
                              <a
                                href="/createnewinternship"
                                className="menu-item"
                              >
                                INTERNSHIP ADMINISTRATION
                              </a>
                            </li>
                            <li className="subMenu__item">
                              <a
                                href="/partnersadministration"
                                className="menu-item"
                              >
                                PARTNERS ADMINISTRATION
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )}
                    <li className="menu__item">
                      <a href="/settings" className="menu-item">
                        SETTINGS
                      </a>
                    </li>
                    <li className="menu__item">
                      <a
                          href="https://www.42prague.com/our-partners"
                          className="menu-item"
                      >
                        PARTNERS
                      </a>
                    </li>
                    <li className="menu__item">
                      <a className="menu-item" onClick={handleLogout}>
                        LOGOUT
                      </a>
                    </li>
                  </ul>
                </nav>
              </Nav>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderComponent;
