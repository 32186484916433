/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import {StagesAll, StagesMainPage} from '../models';
/**
 * StagesControllerApi - axios parameter creator
 * @export
 */
export const StagesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [stageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveByPosition: async (stageId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/archiveStages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stageId !== undefined) {
                localVarQueryParameter['stageId'] = stageId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StagesAll} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStages: async (body: StagesAll, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStages.');
            }
            const localVarPath = `/admin/addStages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers?.['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [stageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByPosition: async (stageId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/deleteStages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stageId !== undefined) {
                localVarQueryParameter['stageId'] = stageId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fieldOfWork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchive: async (fieldOfWork?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/getArchive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fieldOfWork !== undefined) {
                localVarQueryParameter['fieldOfWork'] = fieldOfWork;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} selectedPositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositionDetail: async (selectedPositionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selectedPositionId' is not null or undefined
            if (selectedPositionId === null || selectedPositionId === undefined) {
                throw new RequiredError('selectedPositionId','Required parameter selectedPositionId was null or undefined when calling getPositionDetail.');
            }
            const localVarPath = `/user/positionDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (selectedPositionId !== undefined) {
                localVarQueryParameter['selectedPositionId'] = selectedPositionId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fieldOfWork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStages: async (fieldOfWork?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/getStages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fieldOfWork !== undefined) {
                localVarQueryParameter['fieldOfWork'] = fieldOfWork;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StagesControllerApi - functional programming interface
 * @export
 */
export const StagesControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [stageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveByPosition(stageId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<number>>> {
            const localVarAxiosArgs = await StagesControllerApiAxiosParamCreator(configuration).archiveByPosition(stageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {StagesAll} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStages(body: StagesAll, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<boolean>>> {
            const localVarAxiosArgs = await StagesControllerApiAxiosParamCreator(configuration).createStages(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [stageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByPosition(stageId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<number>>> {
            const localVarAxiosArgs = await StagesControllerApiAxiosParamCreator(configuration).deleteByPosition(stageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [fieldOfWork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArchive(fieldOfWork?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<{ [key: string]: { [key: string]: Array<StagesMainPage>; }; }>>> {
            const localVarAxiosArgs = await StagesControllerApiAxiosParamCreator(configuration).getArchive(fieldOfWork, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} selectedPositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionDetail(selectedPositionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<StagesAll>>>> {
            const localVarAxiosArgs = await StagesControllerApiAxiosParamCreator(configuration).getPositionDetail(selectedPositionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [fieldOfWork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStages(fieldOfWork?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<{ [key: string]: { [key: string]: Array<StagesMainPage>; }; }>>> {
            const localVarAxiosArgs = await StagesControllerApiAxiosParamCreator(configuration).getStages(fieldOfWork, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StagesControllerApi - factory interface
 * @export
 */
export const StagesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [stageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveByPosition(stageId?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return StagesControllerApiFp(configuration).archiveByPosition(stageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StagesAll} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStages(body: StagesAll, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return StagesControllerApiFp(configuration).createStages(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [stageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByPosition(stageId?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return StagesControllerApiFp(configuration).deleteByPosition(stageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fieldOfWork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArchive(fieldOfWork?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<{ [key: string]: { [key: string]: Array<StagesMainPage>; }; }>> {
            return StagesControllerApiFp(configuration).getArchive(fieldOfWork, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} selectedPositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositionDetail(selectedPositionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<StagesAll>>> {
            return StagesControllerApiFp(configuration).getPositionDetail(selectedPositionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fieldOfWork] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStages(fieldOfWork?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<{ [key: string]: { [key: string]: Array<StagesMainPage>; }; }>> {
            return StagesControllerApiFp(configuration).getStages(fieldOfWork, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StagesControllerApi - object-oriented interface
 * @export
 * @class StagesControllerApi
 * @extends {BaseAPI}
 */
export class StagesControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} [stageId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesControllerApi
     */
    public async archiveByPosition(stageId?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<number>> {
        return StagesControllerApiFp(this.configuration).archiveByPosition(stageId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {StagesAll} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesControllerApi
     */
    public async createStages(body: StagesAll, options?: AxiosRequestConfig) : Promise<AxiosResponse<boolean>> {
        return StagesControllerApiFp(this.configuration).createStages(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} [stageId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesControllerApi
     */
    public async deleteByPosition(stageId?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<number>> {
        return StagesControllerApiFp(this.configuration).deleteByPosition(stageId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [fieldOfWork] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesControllerApi
     */
    public async getArchive(fieldOfWork?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<{ [key: string]: { [key: string]: Array<StagesMainPage>; }; }>> {
        return StagesControllerApiFp(this.configuration).getArchive(fieldOfWork, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {number} selectedPositionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesControllerApi
     */
    public async getPositionDetail(selectedPositionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<StagesAll>>> {
        return StagesControllerApiFp(this.configuration).getPositionDetail(selectedPositionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [fieldOfWork] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesControllerApi
     */
    public async getStages(fieldOfWork?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<{ [key: string]: { [key: string]: Array<StagesMainPage>; }; }>> {
        return StagesControllerApiFp(this.configuration).getStages(fieldOfWork, options).then((request) => request(this.axios, this.basePath));
    }
}
