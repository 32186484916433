import React, { FC, useEffect, useState } from "react";
import "./PartnersAdministration.scss";
import {Button, Container, FormSelect, Table} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "react-bootstrap";
import axios from "axios";
import "../HeaderComponent/HeaderComponent";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";

interface Partners {
  name: string;
  partnerRelation: string;
  id: number;
}

const PartnersAdministration: FC<any> = () => {
  const [partners, setPartners] = useState<Partners[]>([]);
  const [partnerRelations, setPartnerRelations] = useState([]);
  const [partnerData, setPartnerData] = useState<{ [key: number]: { name: string; partnerRelation: string } }>({});

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const selectedValue = event.target.value;
    setPartnerData((prevData) => ({ ...prevData, [index]: { ...prevData[index], partnerRelation: selectedValue } }));
  };

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get("/api/admin/getPartners");
        setPartners(response.data);
      } catch (error) {
        console.error("Error fetching partners:", error);
      }
    };

    fetchPartners();
  }, []);

  const handleSaveBtn = async (index: number) => {
    try {
      const updatedPartnerRelation = partnerData[index]?.partnerRelation;
      if (!updatedPartnerRelation) {
        console.error('Failed to get selected partner relation for index:', index);
        return;
      }
      const partnerToUpdate = partners[index];
      partnerToUpdate.partnerRelation = updatedPartnerRelation;
      await axios.put(`/api/admin/updatePartners`, partnerToUpdate);
      console.log('Partner saved successfully!');
      window.location.href = ("/partnersadministration");
    } catch (error) {
      console.error('Failed to save partner:', error);
    }
  };

  // Sort the partners based on partnerRelation in the specified order
  const sortedPartners = partners.sort((a, b) => {
    const order = [
      "FOUNDING PARTNER",
      "CO-FOUNDING PARTNERS",
      "PARTNERS & SUPPORTERS",
      "CURRICULUM PARTNERS",
      "COMMUNITY PARTNERS AND SUPPORTERS"
    ];
    return order.indexOf(a.partnerRelation) - order.indexOf(b.partnerRelation);
  });

  const navigate = useNavigate();
  const handleCreateButtonClick = () => {
    navigate("/createnewpartner");
  };

  return (
    <div className="pageContent">
      <HeaderComponent />
      <div>
        <Container className="Tab">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Partner</th>
                <th>Partner Level</th>
                <th>Control</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(sortedPartners) &&
                sortedPartners.map((partner, index) => (
                  <tr key={index}>
                    <td className="middleTab">{partner.name}</td>
                    <td className="middleTab">{partner.partnerRelation}</td>
                    <td>
                      <FormSelect value={partnerData[index]?.partnerRelation || partner.partnerRelation}
                                  onChange={(e) => handleDropdownChange(e, index)}>
                        <option>FOUNDING PARTNER</option>
                        <option>CO-FOUNDING PARTNERS</option>
                        <option>PARTNERS & SUPPORTERS</option>
                        <option>CURRICULUM PARTNERS</option>
                        <option>COMMUNITY PARTNERS AND SUPPORTERS</option>
                      </FormSelect>

                      <Button onClick={() => handleSaveBtn(index)}>Change Level</Button>

                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Button className="button" onClick={handleCreateButtonClick}>
            Create new partner
          </Button>
        </Container>
      </div>
      <FooterComponent />
    </div>
  );
};

export default PartnersAdministration;
