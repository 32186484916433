import React, { useState } from "react";
import {Form, Button, FormLabel} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { LoginControllerApiAxiosParamCreator } from "../../apis/login-controller-api";
import { Users } from "../../models";
import axios from "axios";
import {Input} from "semantic-ui-react";

const logo = require("../../assets/images/logo-prague.svg").default;

const LoginComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLoginClick = async () => {
    setLoading(true);

    try {
      const body: Users = {
        username,
        password,
      };

      const loginData = LoginControllerApiAxiosParamCreator();
      loginData.login(body);
      const response = await axios.post("/api/login", body);
      localStorage.setItem("role", response.data);
      window.location.href = "/dashboard";
    } catch (error) {
      console.error("Login failed:", error);
      alert("Invalid username or password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-wrapper">
      <img src={logo} width={250} />
      <Form className="login-form">

        <FormLabel>
          Username:
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormLabel>
        <FormLabel>
          Password:
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormLabel>

        <Button onClick={(event) => handleLoginClick()} disabled={loading}>
          {loading ? "Logging In..." : "Login"}
        </Button>
      </Form>
    </div>
  );
};

export default LoginComponent;
