import React, { FC, useEffect, useState } from "react";
import "./CreateNewInternship.scss";
import {
  Form,
  Row,
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  Container,
  Button,
  Dropdown,
  FormSelect,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Partners } from "../../models";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";

interface CreateNewInternshipProps {}

const CreateNewInternship: FC<CreateNewInternshipProps> = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<string>("");
  const [Partners, setPartners] = useState<Partners[]>([]);
  const [internshipData, setInternshipData] = useState({
    stagesPosition: "",
    partner: "",
    stagesLocation: "",
    jobType: "",
    startDate: "",
    duration: "",
    salary: "",
    fieldOfWork: "",
    technologies: "",
    descr: "",
    stagesDetail: "",
    link: "",
  });


  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get("/api/admin/getPartners");
        const fetchedPartners = response.data.map((partner: any) => ({
          ...partner,
        }));
        setPartners(fetchedPartners);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPartners();
  }, []);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = event.target;
    setInternshipData({ ...internshipData, [id]: value });
  };

  const handleQuillChangeDescr: ReactQuillProps["onChange"] = (value) => {
    setInternshipData({ ...internshipData, descr: value });
  };
  const handleQuillChangeStagesDetail: ReactQuillProps["onChange"] = (
    value
  ) => {
    setInternshipData({ ...internshipData, stagesDetail: value });
  };


  const handleSaveButtonClick = async () => {
    try {
      const newInternshipData = {
        ...internshipData,
      };

      await axios.post("/api/admin/addStages", newInternshipData);

      setInternshipData({
        stagesPosition: "",
        partner: "",
        stagesLocation: "",
        jobType: "",
        startDate: "",
        duration: "",
        salary: "",
        fieldOfWork: "",
        technologies: "",
        descr: "",
        stagesDetail: "",
        link: "",
      });
      setData("");
      console.log(data);

      alert("Internship saved successfully");
    } catch (error) {
      console.error("Failed to save partner:", error);
      alert("Failed to save partner:");
    }
  };

  return (
    <div className="pageContent">
      <HeaderComponent />
      <Container className="createContainer">
        <Form className="form">
          <FormGroup className="mb-3" controlId="stagesPosition">
            <FormLabel className="label">Position: </FormLabel>
            <FormControl
              className="internshipHead"
              size="sm"
              type="string"
              placeholder="Test Engineer"
              maxLength={128}
              value={internshipData.stagesPosition}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup className="mb-3" controlId="partner">
            <FormLabel className="label">Partner: </FormLabel>
            <FormSelect
              className="form-select"
              value={internshipData.partner}
              onChange={handleInputChange}
              required
            >
              {Partners.map((partner) => (
                <option key={partner.name}>{partner.name}</option>
              ))}
            </FormSelect>
          </FormGroup>
          <FormGroup className="mb-3" controlId="stagesLocation">
            <FormLabel className="label">Location: </FormLabel>
            <FormControl
              className="internshipHead"
              size="sm"
              type="string"
              placeholder="Prague"
              maxLength={128}
              value={internshipData.stagesLocation}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup className="mb-3" controlId="jobType">
            <FormLabel className="label">Job type: </FormLabel>
            <FormControl
              className="internshipHead"
              size="sm"
              type="string"
              placeholder="Full-time 35-40 hours"
              maxLength={128}
              value={internshipData.jobType}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup className="mb-3" controlId="startDate">
            <FormLabel className="label">Start date: </FormLabel>
            <FormControl
              className="internshipHead"
              size="sm"
              type="string"
              placeholder="Immediately"
              maxLength={128}
              value={internshipData.startDate}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup className="mb-3" controlId="duration">
            <FormLabel className="label">Duration: </FormLabel>
            <FormControl
              className="internshipHead"
              size="sm"
              type="string"
              placeholder="4 months"
              maxLength={128}
              value={internshipData.duration}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup className="mb-3" controlId="salary">
            <FormLabel className="label">Salary: </FormLabel>
            <FormControl
              className="internshipHead"
              size="sm"
              type="string"
              placeholder="XXXXX gross/month"
              maxLength={128}
              value={internshipData.salary}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup className="mb-3" controlId="fieldOfWork">
            <FormLabel className="label">Field of work: </FormLabel>
            <FormControl
              className="internshipHead"
              size="sm"
              type="string"
              placeholder="IT Services and IT Consulting"
              maxLength={128}
              value={internshipData.fieldOfWork}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup className="mb-3" controlId="technologies">
            <FormLabel className="label">Technologies: </FormLabel>
            <FormControl
              className="internshipHead"
              size="sm"
              type="string"
              placeholder="Python / C / C++ / C#"
              maxLength={128}
              value={internshipData.technologies}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup className="mb-3" controlId="descr">
            <FormLabel className="label">Description: </FormLabel>
            <ReactQuill
              className="rich-text-input"
              value={internshipData.descr}
              onChange={handleQuillChangeDescr}
              placeholder="Enter text..."
            />
          </FormGroup>
          <FormGroup>
            <FormLabel className="label">Long description:</FormLabel>
            <ReactQuill
              className="rich-text-input"
              value={internshipData.stagesDetail}
              onChange={handleQuillChangeStagesDetail}
              placeholder="Enter text..."
            />
          </FormGroup>
          <FormGroup className="mb-3" controlId="link">
            <FormLabel className="label">Link: </FormLabel>
            <FormControl
              className="internshipHead"
              size="sm"
              type="string"
              placeholder="www.42Prague.com"
              // maxLength={50}
              value={internshipData.link}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <Button
            variant="primary"
            className="saveButton btn--teal btn--blackHover"
            onClick={handleSaveButtonClick}
          >
            SAVE
          </Button>
        </Form>
      </Container>
      <FooterComponent />
    </div>
  );
};
export default CreateNewInternship;
