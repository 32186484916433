import React, {FC, FormEvent, useEffect, useState} from "react";
import "./CreateNewPartner.scss";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Row,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";

interface CreateNewPartnerProps {}

interface Partners {
  name: string;
  partnerRelation: string;
  id: number;
}

const CreateNewPartner: FC<CreateNewPartnerProps> = () => {
  const [partnerData, setPartnerData] = useState({
    name: "",
    partnerRelation: "",
  });
  const [partners, setPartners] = useState<Partners[]>([]);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get("/api/admin/getPartners");
        setPartners(response.data);
      } catch (error) {
        console.error("Error fetching partners:", error);
      }
    };

    fetchPartners();
  }, []);
  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value } = event.target;
    setPartnerData({ ...partnerData, [id]: value });
  };
  const [validated, setValidated] = useState(false);


  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity()) {
      try {
        // const newPartnersData = { ...partnerData };
        const elements = form.elements as HTMLFormControlsCollection;
        const name = (elements.namedItem("validationCustomName") as HTMLFormElement).value;
        const partnerRelation = (elements.namedItem("validationCustomPartnerRelation") as HTMLSelectElement).value;

        const response = await fetch("/api/admin/addPartner", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            partnerRelation,
          }),
        });

        const responseData = await response.json();

        if (response.ok && responseData) {
          alert("Partner created.")
          window.location.href = "/partnersadministration";
        } else {
          alert("Error creating partner.")
        }
      }catch (error) {
        console.error('Network error:', error)
      }
    }

    setValidated(true);
  };

  return (
    <div className="pageContent">
      <HeaderComponent />
      <Container className="pageSettings">
        <Container className="kontik">
          <Row>
            <Col>
              <Form className="formForPartner d-flex justify-content-center" onSubmit={handleSubmit}>
                <div>
                  <FormGroup className="mb-3" controlId="validationCustomName">
                    <FormLabel className="label">Partner: </FormLabel>
                    <FormControl
                      size="sm"
                      type="string"
                      placeholder="Škoda"
                      maxLength={35}
                      required
                    />
                  </FormGroup>

                  <FormGroup className="mb-3" controlId="validationCustomPartnerRelation">
                    <FormLabel className="label">Level: </FormLabel>

                    <FormSelect
                      className="form-select"
                      required
                    >
                      <option>FOUNDING PARTNER</option>
                      <option>CO-FOUNDING PARTNERS</option>
                      <option>PARTNERS & SUPPORTERS</option>
                      <option>CURRICULUM PARTNERS</option>
                      <option>COMMUNITY PARTNERS AND SUPPORTERS</option>
                    </FormSelect>
                  </FormGroup>
                  <Button
                    variant="primary"
                    className="saveButton btn--teal btn--blackHover"
                    type="submit"
                  >
                    SAVE
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
      <FooterComponent />
    </div>
  );
};

export default CreateNewPartner;
