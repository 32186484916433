import React, { FC } from "react";
import "./InfoPage.scss";
import "../../App.tsx";
import { Container } from "react-bootstrap";
import contentLogo from "../../assets/images/ContentLogo.png";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";

interface InfoPageProps {}

const InfoPage: FC<InfoPageProps> = () => {
  return (
    <div className="pageContent">
      <HeaderComponent></HeaderComponent>
      <Container className="newContent mb-5">
        <img src={contentLogo} alt="content logo" className="content-logo" />
        <Container className="nadpis">
          <h6 className="text-uppercase">Info Page</h6>
          <h2 className="">42 Prague internship platform</h2>
          <p className="text-left">Find your perfect internship experience!</p>
        </Container>
      </Container>
      <Container className="m-10">
        <p>
          <Container>
            If you are reading this, you have acquired some knowledge, and it is
            time to test it in the company to mature your approach to
            programming. That’s when internships, as an integral part of the 42
            Curriculum, come to the game. This platform will provide you with
            current offers from our partners that made the effort to design them
            according to your knowledge.
            <br />
            <br />
            Do not worry if you feel that you do not "know enough" to go to
            work: we know from experience that you are fully capable of meeting
            the needs of companies. You also do not have the vocation to be
            perfect in your technical knowledge because this will also be a
            learning ground!
            <br />
            <br />
            We believe you will discover new horizons, see other things, and it
            will be your opportunity to set new goals and glean new motivations
            (career, startup, etc. - beyond the simple completion of projects
            that you do at school).
            <br />
            <br />
            <h2>General information</h2>
            <br />
            <h3>Internship requirements and rules:</h3>
            <br />
            <ul>
              <li>It must respect a duration from 4 to 6 months full-time.</li>
              <li>
                You must have completed the Common Core when starting the
                internship.
              </li>
              <li>
                Your internship should be on-site, meaning there should be an
                office you have to go to. We accept some amount of work from
                home, but it can’t be the majority of the time as it won’t
                fulfill the purpose of the internship.
              </li>
            </ul>
            <br />
            We, as a campus, decided not to make Startup Internship and
            Part-time Internship available just yet. Please don’t subscribe to
            them if you see the option, it won’t be counted as a part of your
            Curriculum.
            <br />
            <br />
            You can choose from offers available on this platform or find your
            own internship placement. It will just need to follow the rules and
            requirements.
            <br />
            <br />
            <h3>How to start your internship?</h3>
            <br />
            <ol>
              <li>Subscribe to the main project.</li>
              <li>
                You must create and update your contract on the intra-companies
                section (https://companies.intra.42.fr/). Go to "My
                internships,” create a contract (be very careful when filling in
                the information), then download the document and upload it
                signed! .
              </li>
              <li>
                The subprojects "Contract Upload" and "Duration" will be
                automatically evaluated once you have uploaded your contract. No
                manual action is needed!
              </li>
            </ol>
            <br />
            <h3>Legality questions</h3>
            <br />
            In the case of an internship agreement, it must be signed by
            yourself, a representative of the company, and a representative of
            42, because it is a tripartite agreement. In the same way, any
            amendment (extension, break, or any other modification of the
            original contract) must be signed by the same three parties.
            <br />
            <br />
            We are not setting any rules for the legal framework you and the
            internship company should use for your official contract – it can be
            fixed-term or open-ended as long as it is full-time. In practice,
            you will probably be offered HPP or DPP. Feel free to reach out to
            us if you want to consult this topic.
            <br />
            <br />
            Please note that the internship project is not retroactive; we do
            not provide an internship agreement; we do not validate any other
            type of contract - for students who do not fulfill the conditions
            requested (whether they have the agreement of a company or not).
            Also, we don’t approve of freelancing work as a proper internship.
            <br />
            <br />
            <h3>Partnerships Team</h3>
            <br />
            The Partnerships team – Lucia & Tanya; is there to ensure the
            connection between students and companies. We are here to help you
            with finding the perfect internship for you and manage it to all
            parties’ fullest satisfaction. Along with the Pedago team, we will
            be approving your internships and to do that you have to provide us
            with information about your internship by completing the form on the
            intranet in the Companies section and uploading it once it is signed
            by all parties.
            <br />
            <br />
            Additionally, the Partnerships team is there to serve as a link with
            the school during the internship. In case of a problem, doubt, or
            question, address the Partnerships team. We can both advise you but
            also call you to order if necessary. We expect you to only share the
            good name of 42 Prague as a student, and soon-to-be alumni.
            <br />
            <br />
            <h2>Evaluations</h2>
            <br />
            <h3>Mid and final evaluation</h3>
            <br />
            Two evaluations will be sent to your supervisor during the
            internship:
            <br />
            <br />
            <ul>
              <li>
                The first, mid-term evaluation, will be sent halfway through
                your work period.
              </li>
              <li>
                The second, end-of-course evaluation, will be sent one week
                before the end of your contract. Once this second evaluation is
                done, you can subscribe to the peer evaluation project.
              </li>
            </ul>
            <br />
            If you have signed an open-ended contract: when filling in the
            information to generate your contract, please, indicate as "end
            date" 6 months after the starting date of your internship. By doing
            so, your supervisor will receive the email to do your second
            evaluation one week before that date.
            <br />
            <br />
            <h3>Peer video</h3>
            <br />
            Since this is an element of your career, this internship includes an
            evaluation. On the one hand, the training supervisor completes a
            mid-term evaluation and a final evaluation. On the other hand, the
            peer correcting applies to the rendering of each: a video presenting
            the internship period, uploaded on a website like Dailymotion or
            YouTube, etc... and whose link is in a file "video" on your
            rendering git.
            <br />
            <br />
            You have to present in this video what happened during your
            internship in 4 to 5 minutes. It must contain a presentation using
            the tool of your choice (Powerpoint, Keynote, Prezi, ...), as well
            as a miniature inlay of you, at least in the bust, even standing. An
            intro/conclusion where only you appear is possible. This video must
            have the professional side of a presentation to the board of
            directors of a large company. As much for the quality of your
            slides, and your outfit, as the quality of editing and production.
            The content will follow some rules:
            <br />
            <br />
            <ul>
              <li>
                There is a new board member: you will have to explain the
                overall context of the company, the particular context, the
                issues, the content, and the results of the project(s) you did
                during the internship. The new member, without a corporate
                culture, needs to understand what you are explaining.
              </li>
              <li>
                Seeking to promote people on internship, the board is there to
                listen to YOU. It is your personal involvement in the project,
                and your qualities, both technical and managerial (if
                applicable), that interest them. It’s up to you to convince
                them.
              </li>
            </ul>
            <br />
            If you want to get information about the XP calculation, read
            through Chapter VI in the project documentation.
          </Container>
        </p>
      </Container>
      <FooterComponent></FooterComponent>
    </div>
  );
};

export default InfoPage;
