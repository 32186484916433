import React, { FC, useEffect, useState } from "react";
import "./DetailedViewOnInternship.scss";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import contentLogo from "../../assets/images/ContentLogo.png";
import DOMPurify from "dompurify";

interface DetailedViewOnInternshipProps {
  id: number;
}

interface Position {
  stagesPosition: string;
  partner: string;
  descr: string;
  stagesLocation: string;
  jobType: string;
  startDate: string;
  duration: string;
  salary: string;
  fieldOfWork: string;
  technologies: string;
  stagesDetail: string;
  link: string;
  archived: boolean;
}

const DetailedViewOnInternship: FC<DetailedViewOnInternshipProps> = () => {
  const [position, setPosition] = useState<Position | null>(null);
  const { id } = useParams();
  const role = localStorage.getItem("role");
  const [sanitizedStagesDetail, setSanitizedStagesDetail] = useState<
    string | null
  >(null);
  const [sanitizedDescr, setSanitizedDescr] = useState<string | null>(null);

  const handleArchiveStage = async () => {
    try {
      await axios.put(`/api/admin/archiveStages?stageId=${id}`);
      console.log("Internship archive successful");
      alert("Internship archive successful");
      window.location.href = "/dashboard";
    } catch (error) {
      console.error("Failed to archive internship:", error);
      alert("Failed to archive internship");
    }
  };
  const handleDeleteStage = async () => {
    try {
      await axios.delete(`/api/admin/deleteStages?stageId=${id}`);
      console.log("Internship delete successful");
      alert("Internship delete successful");
      window.location.href = "/dashboard";
    } catch (error) {
      console.error("Failed to delete internship:", error);
      alert("Failed to delete internship");
    }
  };

  useEffect(() => {
    fetch(`/api/user/positionDetail?selectedPositionId=${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setPosition(data[0]);
          // Set the sanitizedStagesDetail here after data is fetched
          setSanitizedStagesDetail(DOMPurify.sanitize(data[0].stagesDetail));
          setSanitizedDescr(DOMPurify.sanitize(data[0].descr));
        }
      });
  }, [id]);
  if (!position) {
    return (
      <div className="flex flex-column justify-content-center">Loading...</div>
    );
  }

  const sanitizedHTMLStagesDetail = sanitizedStagesDetail || "";
  const sanitizedHTMLDescr = sanitizedDescr || "";

  return (
    <div className="pageContent">
      <HeaderComponent />
      <Container className="newContent">
        <img src={contentLogo} alt="content logo" className="content-logo" />
        <Container style={{ maxWidth: 1140 }} className="nadpis">
          <h4 className="text-uppercase strong">Internship Detail</h4>
          <h2 className="">{position.stagesPosition}</h2>
          <h3 className="">{position.partner}</h3>
        </Container>
      </Container>
      <Container className="detailView">
        <Row className="stage-view" gap={3}>
          <Col lg={4}>
            <div className="stage-detail">
              <h3>Location:</h3>
              <p>{position.stagesLocation}</p>
              <h3>Job Type:</h3>
              <p>{position.jobType}</p>
              <h3>Start date:</h3>
              <p>{position.startDate}</p>
              <h3>Duration:</h3>
              <p>{position.duration}</p>
              <h3>Salary:</h3>
              <p>{position.salary}</p>
              <h3>Field of work:</h3>
              <p>{position.fieldOfWork}</p>
              <h3>Technologies:</h3>
              <p>{position.technologies}</p>
            </div>
          </Col>
          <Col className="stage-description">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHTMLDescr }}></div>
            <div
              dangerouslySetInnerHTML={{ __html: sanitizedHTMLStagesDetail }}
            ></div>
            {!position.archived && (
              <div>
                <h3>Link:</h3>
                <p>{position.link}</p>
              </div>
            )}
            {role === "admin" && !position.archived && (
              <Button style={{alignSelf: "center"}} onClick={handleArchiveStage}>Archive Internship</Button>
            )}
            {role === "admin" && (
              <Button onClick={handleDeleteStage}>Delete Internship</Button>
            )}
          </Col>
        </Row>
      </Container>
      <FooterComponent />
    </div>
  );
};

export default DetailedViewOnInternship;
