import React, { useEffect, useState } from "react";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import { Button, Container, Form, Card, FormControl } from "react-bootstrap";
import "./SettingsComponent.scss";
import axios from "axios";
import { response } from "express";
import { Input } from "semantic-ui-react";

interface SettingsComponent {}

interface UserSettings {
  id: number;
  username: string;
  password: string;
}

interface UserData {
  username: string;
  role: string;
}

const SettingsComponent: React.FC = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [newPass, setNewPass] = useState("");
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/api/user/profile");
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching username:", error);
      }
    };
    fetchUserData();
  }, []);

  const handlePasswordChange = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    try {
      const response = await axios.put("/api/user/changePass", {
        username: username,
        password: newPass,
      });

      if (response.status === 200) {
        // Password changed successfully
        alert("Password changed successfully!");
        setNewPass(""); // Clear the password input after successful change
      } else {
        // Handle error response from the server
        alert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      // Handle network error
      console.error("Error:", error);
      alert("An error occurred while changing the password.");
    }
  };

  const passChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPass(event.target.value);
  };

  // Extract username and role from userData (if available)
  const username = userData?.username || "";
  const role = userData?.role || "";

  console.log(username);

  return (
    <div className="pageContent">
      <HeaderComponent />
      <Container className="pageSettings">
        <Card>
          <Card.Body className="card-settings">
            <Form className="form-settings" onSubmit={handlePasswordChange}>
            <label className="labelSettings">Username:</label>
            <label className="user">{username}</label>
            <label className="labelSettings">Role:</label>
            <label className="user">{role} </label>
            <label className="labelSettings">Password:</label>

            <Input
              className="passSettings"
              type="password"
              placeholder="Enter new password"
              value={newPass}
              onChange={passChange}
              required
            />
            <Button className="buttonSettings" type="submit">
              Change Password
            </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <FooterComponent />
    </div>
  );
};

export default SettingsComponent;
